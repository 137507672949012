@use "/src/styles/contentWrap.module.scss";
.content-wrap {
	:global .li-value {
		flex: 1;
	}
}
.btnGroups {
	display: flex;
	justify-content: space-evenly;
}
