.loginBox {
	padding: 3rem;
	padding-bottom: 2rem;
	color: white;
	background-color: black;
	min-width: 300px;
	max-height: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loginContainer {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("../assets/login-background.png");
	background-size: cover;
	background-position: center center;
	nav {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0.5rem;
		opacity: 1;
		:hover {
			opacity: 0.7;
		}
		a,
		svg {
			color: var(--button);
			text-decoration: unset;
		}
		a {
			display: flex;
		}
	}
}
