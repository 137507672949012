.li-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 30px;

	.li-label {
		min-width: 200px;
	}

	.li-label-fit-content {
		width: fit-content;
		margin-right: 10px;
	}

	.li-value {
		display: flex;
		flex-direction: column;
		& input {
			padding: 5px;
		}
	}

	.li-disabled {
		color: var(--disabled);
	}

	.li-enabled {
		color: var(--status);
	}
}

.li-container:last-child {
	margin-bottom: 0;
}
