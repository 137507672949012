:root {
	--main: #0d3d6e;
	--second: #4edeea;
	--warning: #ff0000;
	--disabled: #707070;
	--enabled: green;
	--button: #009fdd;
	--log-inout: #00baff;
	--status: #54c3b0;
	--black: black;
	--white: white;
	--tab-active: #09456f;
}
:global(.capitalize) {
	text-transform: capitalize;
}
:global(.mustFill:after) {
	content: " *";
	color: red;
}
